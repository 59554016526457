<template>
  <div class="woo">
    <div class="await">
      <a-spin size="large" class="aspin" />
    </div>
  </div>
</template>
<script>
import { connectStatus } from '@/api/woocom'
export default {
  data() {
    return {
      isSuccess: ''
    }
  },
  async mounted() {
    const isSuccess = this.$route.query.success
    const userid = this.$route.query.user_id
    if (isSuccess === 1) {
      const _this = this
      for (;;) {
        const { data } = await connectStatus({ userId: userid })
        console.log('ddd...')
        console.log(data)
        if (data.status === 2) {
          _this.callNewPage({
            path: '/account',
            query: {
              type: 4
            }
          })
          break
        } else if (data.status === 3) {
          _this.callNewPage({
            path: '/account',
            query: {
              type: 4
            }
          })
        }
        await new Promise(resolve => {
          setTimeout(() => {
            resolve()
          }, 500)
        })
      }
    } else {
      this.callNewPage({ path: '/account', query: { type: 4 }})
    }
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
.await {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  text-align: center;
  z-index: 1;
  display: flex;
  .aspin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
